@import '../../variables';

._row {
    width: 100%;
    display: flex;

    ._menu_right {
        width: calc(100vw - 15vw);
    }

    ._menu_left {
        width: 15vw;
        height: calc(100vh - 130px);
        // border: 1px solid #999;
        position: static;
        box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;

        ul {
            list-style: none;
            padding: 0;

            li {
                a {
                    height: 42px;
                    display: flex;
                    align-items: center;
                    padding: 1.7rem 1rem;
                    line-height: 0.9;
                    transition: border 0.3s;
                    border-left: 3px solid transparent;
                    font-size: 0.9rem;

                    &:hover {
                        border-left: 3px solid #999;
                    }

                    &:active {
                        filter: opacity(0.5);
                    }

                    &.active {
                        font-weight: bold;
                        border-left: 3px solid $PRIMARY_COLOR;
                    }
                }
            }
        }
    }

    ._container_right {
        position: absolute;
        left: calc(15vw + 2.3em);
        // border: 1px solid #999;
        box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;
        width: calc(84vw - 2.2rem);
        height: calc(100vh - 130px);
        overflow: hidden;

        &._user_page {
            overflow-y: scroll;
        }
    }
}

._scrollable_section {
    overflow-x: auto;
    height: calc(100vh - 300px);
    padding: 1rem 0 2em 0;
}

._totals_div {
    padding: 1.3em 2em 2rem;
    align-items: center;

    ._alignBtn {
        margin: 0.5rem 5px 0 -20px;
    }
}

._newCol {
    margin: 0;
    padding: 0;
    max-height: 10px;
    padding-left: 5px !important;
    padding-right: 5px !important;

    .form-group {
        margin-bottom: 0;
    }

    .err {
        color: red;
        font-size: 12px;
        padding: 0.5rem;
        line-height: 1rem;
    }
}

.source_list {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;

    button {
        height: 38px;
        width: 74px;
    }

    div {
        margin: 0 auto;
    }
}

._lead_src {
    max-width: 400px;

    .heading {
        display: block;
        text-align: center;
        margin-bottom: 1rem;
    }
}

._quote {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;

    &.taxes {
        max-width: 600px;
    }

    button {
        margin-top: 1rem;

        &:first-child {
            margin-right: 1rem;
        }

        &:last-child {
            margin-left: 1rem;
        }
    }

    ._input_el {
        display: flex;
        justify-content: space-between;
        margin: 1rem;

        input {
            max-width: 75px;
        }
    }
}

.buttn_widthh {
    width: 75px;
}

.remain_user_label {

    padding: 1.3em 1em 0 1em
}

@media only screen and (max-width: 1200px) {
    .buttn_widthh {
        width: auto;
    }

    .remain_user_label {
        padding: 0em
    }
}