
.select-state {
    padding: .5em;
    width:100%;
    border-radius: 5px;
    background: transparent;
    transition: .5s;
    border: 1px solid #ced4da;
    color: #495057;
    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
    &.error {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background-repeat: no-repeat;
        // background-image: url('./warning.svg');
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
}

