.list_item-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.list_item-enter-active {
    opacity: 1;
    transform: translateX(0%);
}

.list_item-exit {
    opacity: 1;
    transform: translateX(0%);
}

.list_item-exit-active {
    opacity: 0;
    transform: translateX(-100%);
}

.list_item-enter-active,
.list_item-exit-active {
    transition: opacity 500ms, transform 500ms;
}

.__proposal {
    padding: 1em;

    .__empty_block {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(202, 202, 202, 0.466);
        border-radius: 15px;
        border: 2px dashed rgb(184, 184, 184);

        span {
            color: #999;
            font-weight: bold;
            padding: 0 1em;
            text-align: center;
        }
    }
}

.__reload_wrapper {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__reload_btn {
    background: transparent;
    border: 2px solid #111;
    padding: 0.4em 1em;
    cursor: pointer;
}

.__btn_expanded {
    width: 100%;
}

.dropdown-tree-select {
  width: 100%;
  
  .dropdown-content {
    max-height: 400px;
    overflow-y: auto;
  }
}

.permissions-select {
  .select__control {
    min-height: 38px;
    border-color: #ddd;
    
    &:hover {
      border-color: #80bdff;
    }
    
    &--is-focused {
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      border-color: #80bdff;
    }
  }

  .select__menu {
    z-index: 2;
  }

  .select__group {
    padding: 8px;
    
    &-heading {
      font-weight: bold;
      color: #333;
      margin-bottom: 8px;
      padding-left: 30px;
    }
  }

  .select__multi-value {
    background-color: rgba(0, 123, 255, 0.1);
    border-radius: 4px;
    
    &__label {
      color: #007bff;
      font-weight: 500;
    }
    
    &__remove {
      color: #007bff;
      
      &:hover {
        background-color: #007bff;
        color: white;
      }
    }
  }

  .select__placeholder {
    color: #999;
  }

  .select__menu-notice {
    color: #666;
    text-align: center;
    padding: 8px;
  }
}
