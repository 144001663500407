.logo {
    padding: 10px 15px;
}

.nav-links {
    align-items: center;
    padding: 18px 20px;
    color: #fff;
    font-weight: bold;
}

button.nav-links {
    align-items: center;
    padding: 18px 20px;
    color: #fff;
    font-weight: bold;
    background: transparent;
    border: none;
}

.nav-drop-down-menu {
    padding: 10px 20px;
    font-weight: bold;
}

.nav-drop-down-menu .menu-items {
    padding: 15px 20px;
    font-weight: 400;
    display: block;
}

.nav-links:hover,
.nav-links:active,
.nav-drop-down-menu:hover,
.nav-drop-down-menu:active {
    background: rgba(255, 255, 255, 0.945);
    color: #111;
}