

  .archive-button {
    /* Your button styles */
    background-color: white;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .archive-button:hover {
    /* Styles to apply when hovering */
    background-color: white;
  }

  .overflow-cell {
    overflow: hidden; /* or scroll or auto, depending on your preference */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis (...) when text overflows */
  }
  
  .table-responsive {
    overflow-x: auto; /* Add horizontal scrollbar when table content overflows */
  }

  
  .cell-scrollable {
    max-height: 100px; /* Adjust the height as needed */
    overflow: auto;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .table-wrapper {
    min-width: 1000px; /* Set the desired minimum width for the table */
  }
  